/*
 * Usage:
 *
 * html {
 *     font-family: 'Inter', sans-serif;
 * }
 *
 * @supports (font-variation-settings: normal) {
 *     html {
 *        font-family: 'Inter var', sans-serif;
 *     }
 * }
 */
/*
 * Variable font.
 */
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("assets/fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: "Inter var italic";
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("assets/fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2");
}
/*
 * Non-variable font-faces for IE11 or other browsers that don't support them.
 */
@font-face {
  font-family: "Inter-Thin";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Thin-Italic";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Light-X";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Light-Italic-X";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Light-Italic";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Italic";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Italic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Medium-Italic";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Semi-Bold";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Semi-Bold-Italic";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Bold-Italic";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Bold-X";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Bold-Italic-X";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Black";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter-Black-Italic";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"), url("assets/fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "TT Norms Pro Bold";
  src: url("assets/fonts/ttNormsProBold/TTNormsPro-Bold.woff2") format("woff2"), url("assets/fonts/ttNormsProBold/TTNormsPro-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Circular-Bold";
  font-display: swap;
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-bold.eot");
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-bold.eot?#iefix") format("embedded-opentype"), url("https://fonts.brandfolder.com/circular/lineto-circular-bold.woff2") format("woff2"), url("https://fonts.brandfolder.com/circular/lineto-circular-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Circular-Book";
  font-display: swap;
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-book.eot");
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-book.eot?#iefix") format("embedded-opentype"), url("https://fonts.brandfolder.com/circular/lineto-circular-book.woff2") format("woff2"), url("https://fonts.brandfolder.com/circular/lineto-circular-book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Circular-Medium";
  font-display: swap;
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-medium.eot");
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-medium.eot?#iefix") format("embedded-opentype"), url("https://fonts.brandfolder.com/circular/lineto-circular-medium.woff2") format("woff2"), url("https://fonts.brandfolder.com/circular/lineto-circular-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Circular-Italic";
  font-display: swap;
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-bookitalic.eot");
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-bookitalic.eot?#iefix") format("embedded-opentype"), url("https://fonts.brandfolder.com/circular/lineto-circular-bookitalic.woff2") format("woff2"), url("https://fonts.brandfolder.com/circular/lineto-circular-bookitalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Circular-Italic-Medium";
  font-display: swap;
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-mediumitalic.eot");
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-mediumitalic.eot?#iefix") format("embedded-opentype"), url("https://fonts.brandfolder.com/circular/lineto-circular-mediumitalic.woff2") format("woff2"), url("https://fonts.brandfolder.com/circular/lineto-circular-mediumitalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Circular-Black";
  font-display: swap;
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-black.eot");
  src: url("https://fonts.brandfolder.com/circular/lineto-circular-black.eot?#iefix") format("embedded-opentype"), url("https://fonts.brandfolder.com/circular/lineto-circular-black.woff2") format("woff2"), url("https://fonts.brandfolder.com/circular/lineto-circular-black.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
body {
  -webkit-font-smoothing: antialiased;
}
body select[disabled], body select:disabled,
body input[disabled],
body input:disabled {
  background-color: #f5f5f5;
  color: #9b9b9b !important;
}
body select:invalid,
body input:invalid {
  color: #9b9b9b !important;
}
body .title {
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 30px;
}
.theme-brandfolder body, body .theme-brandfolder {
  color: rgb(74, 74, 74);
  font-family: "Circular-Book", "Arial", "Trebuchet MS", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.theme-brandfolder body h1, body .theme-brandfolder h1 {
  color: rgb(74, 74, 74);
  font-family: "Circular-Bold", "Arial", "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.theme-brandfolder body h2, body .theme-brandfolder h2 {
  color: rgb(74, 74, 74);
  font-family: "Circular-Bold", "Arial", "Trebuchet MS", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.theme-brandfolder body h3, body .theme-brandfolder h3 {
  color: rgb(74, 74, 74);
  font-family: "Circular-Medium", "Arial", "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.theme-brandfolder body label, body .theme-brandfolder label {
  font-family: "Circular-Medium", "Arial", "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.theme-brandfolder body select, body .theme-brandfolder select {
  font-size: 14px;
  line-height: 23px;
}
.theme-brandfolder body .font-medium, body .theme-brandfolder .font-medium {
  font-family: "Circular-Medium", "Arial", "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.theme-brandfolder body .font-semi-bold, body .theme-brandfolder .font-semi-bold {
  font-family: "Circular-Medium", "Arial", "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.theme-brandfolder body .font-bold, body .theme-brandfolder .font-bold {
  font-family: "Circular-Bold", "Arial", "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.theme-brandfolder body .link, body .theme-brandfolder .link {
  font-family: inherit;
  text-decoration: underline 1px transparent;
  transition: text-decoration-color 0.3s ease;
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 23px;
  color: rgb(68, 98, 201);
}
.theme-brandfolder body .link:hover, body .theme-brandfolder .link:hover {
  color: #314ca9;
  text-decoration-color: transparent;
}
.theme-brandfolder body .cta-link, body .theme-brandfolder .cta-link {
  font-family: inherit;
  text-decoration: underline 1px transparent;
  transition: text-decoration-color 0.3s ease;
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 23px;
  color: rgb(74, 74, 74);
  font-weight: 600;
}
.theme-smartsheet body, body .theme-smartsheet {
  color: #111111;
  font-family: "Inter var", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.theme-smartsheet body h1, body .theme-smartsheet h1 {
  color: #111111;
  font-family: "TT Norms Pro Bold", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
}
.theme-smartsheet body h2, body .theme-smartsheet h2 {
  color: #111111;
  font-family: "TT Norms Pro Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}
.theme-smartsheet body h3, body .theme-smartsheet h3 {
  color: #111111;
  font-family: "Inter-Semi-Bold", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.theme-smartsheet body label, body .theme-smartsheet label {
  font-family: "Inter-Medium", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 500;
}
.theme-smartsheet body select, body .theme-smartsheet select {
  font-size: 13px;
  line-height: 18px;
}
.theme-smartsheet body .font-medium, body .theme-smartsheet .font-medium {
  font-family: "Inter-Medium", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 500;
}
.theme-smartsheet body .font-semi-bold, body .theme-smartsheet .font-semi-bold {
  font-family: "Inter-Semi-Bold", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 600;
}
.theme-smartsheet body .font-bold, body .theme-smartsheet .font-bold {
  font-family: "Inter-Bold", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 700;
}
.theme-smartsheet body .link, body .theme-smartsheet .link {
  font-family: "Inter", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  text-decoration: underline 1px transparent;
  transition: text-decoration-color 0.3s ease;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #0970be;
}
.theme-smartsheet body .link:hover, body .theme-smartsheet .link:hover {
  color: #0970be;
  text-decoration-color: #0970be;
}
.theme-smartsheet body .cta-link, body .theme-smartsheet .cta-link {
  font-family: "Inter", "Arial", "Helvetica", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  text-decoration: underline 1px transparent;
  transition: text-decoration-color 0.3s ease;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #3576ba;
  font-weight: 600;
}

#getting-started-container {
  padding: 11px 10px;
}

.w-2\/5 {
  width: 30%;
  padding: 15px 0 15px 22px;
}

.leading-10 {
  line-height: 3.5rem;
  padding-right: 22px;
}

.flex.items-center.justify-center.border.rounded.border-bf-lighter-gray {
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
}

.flex.items-center.justify-center.border.rounded.border-bf-lighter-gray:hover {
  border-color: #4a4a4a;
}

.mr-sm {
  margin-right: 4px;
  margin-top: -1px;
}

.search-ph-md::-webkit-input-placeholder {
  font-size: 14px;
}

.search-ph-md::-moz-placeholder {
  font-size: 14px;
}

.search-ph-md:hover {
  border-color: #4a4a4a;
}

.attachment-text-md {
  font-family: "Circular-Medium";
  font-size: 12px;
}

.showpage-filter-top-wrapper {
  margin-bottom: 16px;
}

.bf-collections-anchor-text {
  color: #4a4a4a !important;
  margin-right: 10px;
}
.bf-collections-anchor-text:hover {
  text-decoration-color: transparent !important;
}

.attachment-adjust-margin {
  transform: translateY(-0.5px);
}

.advanced-filter-apply-overlay {
  position: sticky;
  bottom: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.925);
  max-width: 400px;
  width: inherit;
}

body {
  overscroll-behavior: none;
  background-color: #FFFFFF;
}

.right-0 {
  right: 0;
}